<template>
  <v-card max-width="600" style="margin-top: 5vh" class="mx-auto">
    <v-card-title> Reset Password </v-card-title>
    <v-container>
      <v-form ref="form" v-model="valid" @submit.prevent="submit()">
        <v-alert type="error" text v-if="error">{{ error }}</v-alert>
        <v-alert type="success" success v-if="success">{{ success }}</v-alert>

        <password-field
          label="Password"
          v-model="password"
          :rules="rules.passwordRule"
        ></password-field>
        <password-field
          v-model="confirmPassword"
          :rules="confirmPasswordRule"
          label="Confirm Password"
        ></password-field>
        <div class="text-center" v-if="success">
          <v-alert color="info" text>
            You can now continue to Login page and use your new password
          </v-alert>
          <v-btn color="success" large to="/login">Login</v-btn>
        </div>
        <v-btn v-else color="primary" :loading="loading" @click="submit()" large
          >Change</v-btn
        >
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import firebase from 'firebase/app'

import rules from '@/mixins/rules'
import 'firebase/auth'
export default {
  mixins: [rules],
  data: () => ({
    password: '',
    confirmPassword: '',
    success: null,
    error: null,
    loading: false,
    valid: true
  }),
  computed: {
    confirmPasswordRule() {
      return [
        v => !!v || 'Confirm Password is required',
        v => v === this.password || 'The password confirmation does not match.'
      ]
    }
  },
  methods: {
    submit() {
      this.error = null
      this.success = null
      this.$refs.form.validate()
      if (this.valid) {
        this.resetPassword()
      }
    },
    async resetPassword() {
      let code = this.$route.query.oobCode
      this.loading = true

      await firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then(async () => {
          await firebase
            .auth()
            .confirmPasswordReset(code, this.password)
            .then(() => {
              this.success = 'Your password has been successfully changed'
              this.$refs.form.reset()
            })
        })
        .catch(err => {
          if (err.message) {
            this.error = err.message
          } else {
            this.error = 'Invalid password reset link'
          }
        })
      this.loading = false
    }
  }
}
</script>

<style></style>
