<template>
  <v-main>
    <v-container>
      <reset-password v-if="mode == 'resetPassword'"></reset-password>
    </v-container>
  </v-main>
</template>

<script>
import ResetPassword from '@/components/ResetPassword'
export default {
  components: {
    ResetPassword
  },
  data: () => ({
    title: ''
  }),
  metaInfo() {
    return {
      title: this.title
    }
  },
  mounted() {
    this.validateMode()
  },
  computed: {
    mode() {
      return this.$route.query.mode
    }
  },
  methods: {
    validateMode() {
      if (this.mode == 'resetPassword') {
        this.title = 'Reset Password'
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style></style>
